import "./AboutCourse.css";
import { JoinButton } from '../JoinButton/JoinButton';

const items = [{
    title: "Тренування",
    items: [
        "- 12 різноманітних тренувань (барре, функціональний тренінг, стретчинг)",
        "- спортивний челендж",
        "- бонусні тренування та зарядки"
    ]
}, {
    title: "Харчування",
    items: [
        "- лекції у прямому ефірі про збалансоване харчування та формування продуктового кошика",
        "- раціон на три тижні (без підрахунку КБЖВ)",
        "- збірки страв при відключеннях світла"
    ]
}, {
    title: "Додатково",
    items: [
        "- чек-листи та трекери звичок",
        "- спільний чат з учасницями та куратором @chv.lera",
        "- щоденна підтримка та мотивація",
        "- можливість виграти круті подарунки від українських брендів за активність та найкращі результати",
    ]
}, {
    title: "ЦІНА",
    items: [
        (
            <div className="font-semibold">
                <div><span className="line-through">1399 грн</span> 1199 грн</div>
                <div>[тільки до 22.09]</div>
                <div className="mt-5">[з 23.09 вартість 1399 грн]</div>
            </div>
        )
    ]
}]

export function AboutCourse() {
    return (
        <>
            <div className="uppercase mb-10">[про інтенсив]</div>
            {items.map((block, i) => {
                return <div className="AboutCourse--block text-regular" key={i}>
                    <div className="AboutCourse--title uppercase mb-9">
                        {block.title}
                    </div>
                    {block.items.map((item, i) => {
                        return <div className="AboutCourse--item mt-5" key={i}>{item}</div>
                    })}
                </div>
            })}
            <div className="my-10">
                <JoinButton/>
            </div>
        </>
    )
}
